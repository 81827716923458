@import 'colors';

#product-hunt {
  display: none;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lighten(#b74424, 2%);
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: 1.4rem;
    z-index: 10;
    padding: 1em;
    img {
      height: 40px;
      width: 40px;
      margin-right: 50px;
    }
    &:hover {
      background-color: lighten(#b74424, 5%);
      color: #fff;
    }
  }
}

#fixed-banner {
  text-align: center;
  background-color: #444;
  color: #fff;
  font-weight: 600;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  a {
    padding: 1em;
    display: block;
    color: #fff;
  }
}

nav#page-header {
  max-width: 960px;
  width: 90%;
  margin: 40px auto;
  padding: 0em 1em;

  &.no-banner {
    margin: 40px auto;
  }

  @media (max-width: 720px) {
    padding: 0em 1em;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-container {
    height: 50px;
    //width: 50px;

    .logo {
      //width: 100%;
      height: 30px;
      margin: 10px 0;
    }
  }

  @media (min-width: 721px) {
    #hamburger,
    #x,
    #home-link {
      display: none;
    }

    .nav-link {
      margin-top: 1em;

      a {
        padding: 0.65em 0.8em 0.55em 0.8em;
      }

      a.outline {
        border: 1px solid $primary-color;
        border-radius: 4px;
        margin-bottom: 1em;

        &:hover {
          background-color: #fff;
        }
      }

      #nav-container {
        display: flex;
        align-items: center;
      }

      .nav-dropdown-container {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-left: 0.5em;
        margin-right: 0.5em;

        &:hover {
          .nav-dropdown {
            display: flex;
          }

          a.dropdown-trigger {
            // box-shadow: inset 2px 2px 1px #eee;
            border-bottom-color: $hover-color;
            // background-color: #fff;
          }
        }

        a.dropdown-trigger {
          padding: 0.15em;
          margin-right: 1em;
          margin-bottom: 1em;
          border-bottom-width: 2px;
          border-bottom-style: solid;
          border-bottom-color: #fafafa;
        }

        .nav-dropdown {
          z-index: 1000;
          background-color: #fff;
          display: none;
          flex-direction: column;
          position: absolute;
          top: 2.5em;
          box-shadow: 2px 2px 1px #eee;

          a {
            white-space: nowrap;
            &:hover {
              background-color: $primary-color;
              color: #fff;
            }
          }
        }
      }
    }
  }

  /* Mobile */

  @media (max-width: 720px) {
    #hamburger {
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #444;
      border-radius: 4px;
      padding: 8px 8px;
      &:hover {
        cursor: pointer;
      }
    }

    #home-link {
      display: block;
    }

    #nav-container {
      #x {
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid #444;
        border-radius: 4px;
        padding: 10px;
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 101;
        background-color: #fff;
        &:hover {
          cursor: pointer;
        }
      }

      display: none;
      align-items: flex-start;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      z-index: 100;
      padding: 4em 1em 1em 1em;
      box-sizing: border-box;

      .nav-dropdown-container {
        width: 100%;
      }

      a.dropdown-trigger {
        width: 100%;
        display: block;
        border-bottom: 2px solid $primary-color;
        padding-bottom: 0.5em;
        margin-bottom: 1em;
      }

      .nav-dropdown {
        padding-left: 1em;
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;

        a {
          margin-bottom: 1em;
        }
      }
    }
  }
}
